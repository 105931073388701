import services from '@/http';
import { user } from '@/http';
import * as types from './mutation-types';


export const ActionSendEmailOrSmsText = (context, payload) => {
    user.sendSmsOrEmail.data = payload;
    
    return services.http.request(user.sendSmsOrEmail)
        .then(response => {
            return response;
        })
        .catch((err) => {
            return err;
        });
}

export const ActionSendAccessLog = (context, payload) => {
    user.sendLogAccess.data = payload;

    return services.http.request(user.sendLogAccess)
        .then(response => {
            return response;
        })
        .catch((err) => {
            return err;
        });
}


export const ActionSendAccessLogWithoutToken = (context, payload) => {
    user.sendLogAccessWithoutToken.data = payload;

    return services.http.request(user.sendLogAccessWithoutToken)
        .then(response => {
            return response;
        })
        .catch((err) => {
            return err;
        });
}

export const ActionFindBoletoExpress = ({ dispatch }) => {
    return services.http.request(user.findBoletoExpresso)
        .then(response => {
            dispatch("ActionSetBoletoExpresso", response.data.results);
            return response;
        })
}

export const ActionListAgreements = () => {
    return services.http.request(user.contractsListAgreements)
        .then(response => {
            return response.data.results;
        })
}

export const ActionCancelAgreement = (_, agreement_id) => {
    user.contractsCancelAgreement.url = user.contractsCancelAgreement.url.replace('{agreement_id}', agreement_id);
    return services.http.request(user.contractsCancelAgreement)
        .then(response => {
            user.contractsCancelAgreement.url = 'concilig/v2/cancelAgreement?IdAcordo={agreement_id}';
            return response.data;
        })
}

export const ActionSetBoletoExpresso = ({ commit }, boletos) => {
    commit(types.SET_BOLETO_EXPRESSO, boletos);
}


export const ActionSetDefaultStates = ({commit}) => {
    commit(types.SET_BOLETO_EXPRESSO, []);
}
