import auth from "@/middleware/auth";

export default [
    {
        path: "meus-contratos",
        name: "meusContratos",
        meta: { middleware: [auth] },
        component: () => import(/* webpackChunkName: "minhas-dividas" */ "./MeusContratos"),
    }
];
