import moment from 'moment';

export const hasError = (error) => {
    const errorMessage = "Erro na API, por favor tente novamente.";
  
    if (error.data === "Fetch User") {
      return error.message;
    }
  
    if (!error) {
      console.error(`API ${error.config.url} não encontrada`);
      return errorMessage;
    }
    if (process.env.NODE_ENV === "development") {
      console.error(error.status, error.data.message);
    }
    if (error.data && error.data.message) {
      return error.data.message;
    }
  
    return errorMessage;
};

export const formatCurrencieValueBRL = (value) => {
    let val = (value/1).toFixed(2).replace('.', ',')
    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
};

export const formatRemovePhoneMask= (value) => {
  return value.toString().replace('(','').replace(')','').replace(' ','').replace('-','').replace(' ','');
};

export const formatDocument = (value) => {
  
  if (value.lenght > 11) {
    value = value.padStart(14, '0');
    value = value.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
  } else {
    value = value.padStart(11, '0');
    value = value.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
  }
  return value;
}

export const formatData = (value, currentFormat, expectedFormat) => {
    moment.locale('pt-br');
    return moment(value, currentFormat).format(expectedFormat);
};

export const formatCep = (value) => {
  value = value.padStart(8, '0');
  value = value.replace(/^(\d{5})(\d{3})/, '$1-$2');
  return value;
};

export const getNameInitials = (name) => {
  let rgx = new RegExp(/(\p{L}{1})\p{L}+/, 'gu');

  let initials = [...name.matchAll(rgx)] || [];

  initials = (
    (initials.shift()?.[1] || '') + (initials.pop()?.[1] || '')
  ).toUpperCase();

  return initials;
}

export const validaCpfCnpj = (val) => {
  var v1 = 0;
      var v2 = 0;
      var p;
      var p1;
      var p2;
      var i = 1;
      var aux = false;

  if (val.length == 14) {
      var cpf = val.trim();
   
      cpf = cpf.replace(/\./g, '');
      cpf = cpf.replace('-', '');
      cpf = cpf.split('');
      
      
      
      for (i = 1; cpf.length > i; i++) {
          if (cpf[i - 1] != cpf[i]) {
              aux = true;   
          }
      } 
      
      if (aux == false) {
          return false; 
      } 
      

      for (i = 0, p = 10; (cpf.length - 2) > i; i++, p--) {
          v1 += cpf[i] * p; 
      } 
      
      v1 = ((v1 * 10) % 11);
      
      if (v1 == 10) {
          v1 = 0; 
      }
      
      if (v1 != cpf[9]) {
          return false; 
      } 
      
      for (i = 0, p = 11; (cpf.length - 1) > i; i++, p--) {
          v2 += cpf[i] * p; 
      } 
      
      v2 = ((v2 * 10) % 11);
      
      if (v2 == 10) {
          v2 = 0; 
      }
      
      if (v2 != cpf[10]) {
          return false; 
      } else {   
          return true; 
      }
  } else if (val.length == 18) {
      var cnpj = val.trim();
      
      cnpj = cnpj.replace(/\./g, '');
      cnpj = cnpj.replace('-', '');
      cnpj = cnpj.replace('/', ''); 
      cnpj = cnpj.split(''); 
      

      for (i = 1; cnpj.length > i; i++) { 
          if (cnpj[i - 1] != cnpj[i]) {  
              aux = true;   
          } 
      } 
      
      if (aux == false) {  
          return false; 
      }
      
      for (i = 0, p1 = 5, p2 = 13; (cnpj.length - 2) > i; i++, p1--, p2--) {
          if (p1 >= 2) {  
              v1 += cnpj[i] * p1;  
          } else {  
              v1 += cnpj[i] * p2;  
          } 
      } 
      
      v1 = (v1 % 11);
      
      if (v1 < 2) { 
          v1 = 0; 
      } else { 
          v1 = (11 - v1); 
      } 
      
      if (v1 != cnpj[12]) {  
          return false; 
      } 
      
      for (i = 0, p1 = 6, p2 = 14; (cnpj.length - 1) > i; i++, p1--, p2--) { 
          if (p1 >= 2) {  
              v2 += cnpj[i] * p1;  
          } else {   
              v2 += cnpj[i] * p2; 
          } 
      }
      
      v2 = (v2 % 11); 
      
      if (v2 < 2) {  
          v2 = 0;
      } else { 
          v2 = (11 - v2); 
      } 
      
      if (v2 != cnpj[13]) {   
          return false; 
      } else {  
          return true; 
      }
  } else {
      return false;
  }
}

export const ActionGetIPAddress = async () => {
    return await fetch('https://api.ipify.org?format=json')
    .then(x => x.json())
    .then(({ ip }) => {
        return ip;
    });
}