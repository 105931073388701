import auth from "@/middleware/auth";

export default [
    {
        path: "negociar",
        name: "negociar",
        meta: { middleware: [auth] },
        component: () => import("./Negociar")
    }
];
