import middlewarePipeline from "./middlewarePipeline";
import store from "@/store";

export default async (to, from, next) => {
    //document.title = `${to.name} - ${process.env.VUE_APP_TITLE}`;
    if(to.name == !'landing')
        document.title = `${process.env.VUE_APP_TITLE}`;
        
    const middleware = to.meta.middleware;
    const context = { to, from, next, store };

    if (!middleware) {
        return next();
    }
    middleware[0]({
        ...context,
        next: middlewarePipeline(context, middleware, 1),
    });
}