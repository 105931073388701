import auth from "@/middleware/auth";

export default [
    {
        path: "boleto-acordo",
        name: "boletoAcordo",
        meta: { middleware: [auth] },
        component: () => import("./BoletoAcordo")
    }
];
