import auth from "@/middleware/auth";
import { routes as meusContratos } from "./meusContratos/";
import { routes as cadastro } from "./cadastro/";
import { routes as comprovante } from "./comprovante/";
import { routes as meusAcordos } from "./meusAcordos/";
import { routes as negociarDivida } from "./negociarDivida/";
import { routes as meusBoletos } from "./meusBoletos/";
import { routes as negociar } from "./negociar/";
import { routes as boletoAcordo } from "./boletoAcordo/";
import { routes as vencimentoParcelas } from "./vencimentoParcelas/";
import { routes as acordoFechado } from "./acordoFechado/";
import { routes as resumoAcordo } from "./resumoAcordo/";
import { routes as acordoFechadoParcelas } from "./acordoFechadoParcelas/";
import { routes as propostas } from "./propostas/";


export default [
    {
        path: "/dashboard",
        component: () => import(/* webpackChunkName: "dashboard-layout" */ "@/modules/layout/DashboardLayout"),
        children: [
            {
                path: "/",
                name: "dashboard",
                meta: { middleware: [auth] },
                component: () => import(/* webpackChunkName: "dashboard" */ "./Dashboard")
            },
            ...meusContratos,
            ...meusAcordos,
            ...cadastro,
            ...negociarDivida,
            ...meusBoletos,
            ...comprovante,
            ...negociar,
            ...boletoAcordo,
            ...vencimentoParcelas,
            ...acordoFechado,
            ...acordoFechadoParcelas,
            ...resumoAcordo,
            ...propostas,
        ]
    }
];