import services from '@/http';
import { user } from '@/http';
import * as types from './mutation-types';


export const ActionDownloadBoleto = (context, payload) => {
    user.downloadBill.url = user.downloadBill.url.replace('{contratoParcela}',payload['contratoParcela'])
    .replace('{linhaDigitavel}',payload['linhaDigitavel']);

    return services.http.request(user.downloadBill)
        .then(response => {
            user.downloadBill.url = 'boleto?ContratoParcela={contratoParcela}&LinhaDigitavel={linhaDigitavel}';
            return response;
        })
        .catch((err) => {
            user.downloadBill.url = 'boleto?ContratoParcela={contratoParcela}&LinhaDigitavel={linhaDigitavel}';
            return err;
        });
}

export const ActionSendEmailOrSmsText = (context, payload) => {
    user.sendSmsOrEmail.data = payload;
    
    return services.http.request(user.sendSmsOrEmail)
        .then(response => {
            return response;
        })
        .catch((err) => {
            return err;
        });
}

// export const ActionSendAccessLog = (context, payload) => {
//     user.sendLogAccess.data = payload;

//     return services.http.request(user.sendLogAccess)
//         .then(response => {
//             return response;
//         })
//         .catch((err) => {
//             return err;
//         });
// }

// export const ActionFindDividas = ({ dispatch }) => {
//     return services.http.request(user.findDividas)
//         .then(response => {
//             dispatch("ActionSetDividas", response.data.results);
//             return response;
//         })
// }

// export const ActionFindBoletoExpress = ({ dispatch }) => {
//     return services.http.request(user.findBoletoExpresso)
//         .then(response => {
//             dispatch("ActionSetBoletoExpresso", response.data.results);
//             return response;
//         })
// }

// export const ActionDividaNegociar = ({ dispatch }, contractNumber) => {
//     user.dividaNegociar.url = user.dividaNegociar.url + contractNumber;
//     return services.http.request(user.dividaNegociar)
//         .then(response => {
//             user.dividaNegociar.url = 'offers?ContractNumber=';
//             dispatch("ActionSetNegociacao", response.data.results);
//             return response;
//         }).catch(error => {
//             user.dividaNegociar.url = 'offers?ContractNumber=';
//             return error;
//         });
// }

// export const ActionFindDividasPagination = ({ dispatch, rootState }, page_id) => {
//     user.findDividasPagination.url = user.findDividasPagination.url.replace('{user_id}', rootState.auth.user.id);
//     user.findDividasPagination.url = user.findDividasPagination.url + page_id;
//     return services.http.request(user.findDividasPagination)
//         .then(response => {
//             user.findDividasPagination.url = 'users/{user_id}/dividas?page='
        
//             dispatch("ActionSetDividas", response.data.results);
//             return response;
//         })
//         .catch(error => {
//             user.findDividasPagination.url = 'users/{user_id}/dividas?page='

//             return error;
//         });
// }

// export const ActionFindPagamento = (context, divida_id) => {
//     divida.findPagamento.url = divida.findPagamento.url.replace('{divida_id}', divida_id);
//     return services.http.request(divida.findPagamento)
//         .then(response => {
//             divida.findPagamento.url = 'dividas/{divida_id}/pagamento';
//             return response;
//         })
//         .catch(error => {
//             divida.findPagamento.url = 'dividas/{divida_id}/pagamento';
//             return error;
//         });
// }

// export const ActionFindEmpresa = (context, user_id) => {
//     user.findEmpresa.url = user.findEmpresa.url.replace('{user_id}', user_id);
//     return services.http.request(user.findEmpresa);
// }

// export const ActionFinishAcordo = (context, acordo) => {
//     divida.finishAcordo.data = acordo;
//     return services.http.request(divida.finishAcordo)
//         .then(response => {
//             return response
//         }).catch(error => {
//             return error;
//         })
// }

// export const ActionGetContractsNegotiable = ({ dispatch}, payload) => {
//     user.getContractsNegotiable.data = payload;
//     return services.http.request(user.getContractsNegotiable)
//         .then(response => {
//             dispatch("ActionSetContractsNegotiable", response.data.results);
//         }).catch((e) => {
//             console.log(e);
//             dispatch("ActionSetContractsNegotiable", []);
//         })
// }

// export const ActionGetContractsNegotiableOptions = ({ dispatch}, payload) => {
//     try{
//         user.getContractsNegotiableOptions.data = payload;
//         return services.http.request(user.getContractsNegotiableOptions)
//             .then(response => {
//                 dispatch("ActionSetContractsNegotiableOptions", response.data.results);
//                 return response;
//             }).catch((e) => {
//                 dispatch("ActionSetContractsNegotiableOptions", []);
//                 return e;
//             })
//     }catch(_){
//         return null;
//     }
    
// }

// export const ActionContractsAgreementResume = ({ dispatch}, payload) => {
//     user.contractsAgreementResume.data = payload;
//     return services.http.request(user.contractsAgreementResume)
//         .then(response => {
//             dispatch("ActionSetContractsAgreementResume", response.data.results);
//         }).catch((e) => {
//             console.log(e);
//             dispatch("ActionSetContractsAgreementResume", []);
//         })
// }



// export const ActionGetContracts = () => {
//     return services.http.request(user.getContracts)
//         .then(response => {
//             return response.data.results;
//         }).catch((e) => {
//            return e;
//         })
// }

export const ActionGetDebtsClaro = ( _, payload) => {
    user.getDebtsClaro.data = payload;
    return services.http.request(user.getDebtsClaro)
        .then(response => {
            return response.data.results;
        }).catch((e) => {
            console.log(e);
           return false;
        })
}

export const ActionDownloadBill = ( _, payload) => {
    user.downloadBill.data = payload;
    return services.http.request(user.downloadBill)
        .then(response => {
            // console.log('RESPONSE', response.data.results)
            return response.data.results;
        }).catch((e) => {
            console.log(e);
           return false;
        })
}

export const ActionGetOffersClaro = ( _, payload) => {
    user.getOffersClaro.data = payload;
    return services.http.request(user.getOffersClaro)
        .then(response => {
            return response.data.results;
        }).catch((e) => {
            console.log(e);
           return false;
        })
}


export const ActionSetAgreement = ( _,payload) => {
    user.getAgreementClaro.data = payload;
    return services.http.request(user.getAgreementClaro)
        .then(response => {
          return response.data.results;
        }).catch((e) => {
            console.log(e);
           return false;
        })
}

export const ActionInsertValuesContract = ( _, payload) => {
    user.insertValuesContract.data = payload;
    return services.http.request(user.insertValuesContract)
        .then(response => {
            return response.data.results;
        }).catch((e) => {
            console.log(e);
           return false;
        })
}

export const ActionGetValuesContract = ( _, payload) => {
    user.getValuesContract.data = payload;
    return services.http.request(user.getValuesContract)
        .then(response => {
            return response.data.results;
        }).catch((e) => {
            console.log(e);
           return false;
        })
}

export const ActionDeleteValuesContract = ( _, payload) => {
    user.deleteValuesContract.data = payload;
    return services.http.request(user.deleteValuesContract)
        .then(response => {
            return response.data.results;
        }).catch((e) => {
            console.log(e);
           return false;
        })
}

export const ActionGetInstallmentsClaro = () => {
    return services.http.request(user.getInstallmentsClaro)
        .then((response) => response.data?.results)
        .catch((e) => {
            console.log(e);
           return false;
        })
}

export const ActionInsertLogByValueId = ( _, payload) => {
    user.insertLogByValueId.data = payload;
    return services.http.request(user.insertLogByValueId)
        .then(response => {
            return response.data.results;
        }).catch((e) => {
            console.log(e);
           return false;
        })
}

// commit é responsável por chamar a mutation
export const ActionSetContractsNegotiable = ({ commit }, contracts) => {
    commit(types.SET_NEGOTIABLE, contracts);
}

// commit é responsável por chamar a mutation
export const ActionSetParcelamentSelected = ({ commit }, parcelament) => {
    commit(types.SET_PARCELAMNENT_SELECTED, parcelament);
}

// commit é responsável por chamar a mutation
export const ActionSetContractsNegotiableOptions = ({ commit }, contracts) => {
    commit(types.SET_NEGOTIABLE_OPTIONS, contracts);
}

// commit é responsável por chamar a mutation
export const ActionSetContractsAgreementResume = ({ commit }, resume) => {
    commit(types.SET_RESUME, resume);
}

// commit é responsável por chamar a mutation
export const ActionSetDividas = ({ commit }, dividas) => {
    commit(types.SET_DIVIDAS, dividas);
}

export const ActionSetBoletoExpresso = ({ commit }, boletos) => {
    commit(types.SET_BOLETO_EXPRESSO, boletos);
}

export const ActionSetNegociacao = ({ commit }, deal) => {
    commit(types.SET_NEGOCIACAO, deal);
}

export const ActionSetAcordo = ({ commit }, acordo) => {
    commit(types.SET_ACORDO, acordo);
}

export const ActionSetStep = ({ commit }, step) => {
    commit(types.SET_STEP, step);
}

export const ActionSetCurrentComponent = ({ commit }, currentComponent) => {
    commit(types.SET_CURRENTCOMPONENT, currentComponent);
}

export const ActionSetDefaultStates = ({commit}) => {
    commit(types.SET_DIVIDAS, []);
    commit(types.SET_BOLETO_EXPRESSO, []);
    commit(types.SET_ACORDO, {});
    commit(types.SET_STEP, 1);
    commit(types.SET_CURRENTCOMPONENT, "negociar-divida");
}

export const ActionAcceptAgreement = async (context, payload) => {
    user.sendAcceptAgreement.data = payload;
    return await services.http.request(user.sendAcceptAgreement)
        .then(async response => {
            return response.data;
        })
}

export const ActionDownloadV2Boleto = (context, payload) => {
    user.downloadBoleto.data = payload;

    return services.http.request(user.downloadBoleto)
        .then(response => {
            // user.downloadBill.url = 'boleto?ContratoParcela={contratoParcela}&LinhaDigitavel={linhaDigitavel}';
            return response;
        })
        .catch((err) => {
            // user.downloadBill.url = 'boleto?ContratoParcela={contratoParcela}&LinhaDigitavel={linhaDigitavel}';
            return err;
        });
}
