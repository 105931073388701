import Vue from "vue";
import App from "./App";
import "./registerServiceWorker";
import store from "./store";
import router from "./router";
import vuetify from "./plugins/vuetify";
import Vuelidate from 'vuelidate';
import FlashMessage from '@smartweb/vue-flash-message';
import Axios from '@/http'
import money from 'v-money';
import VModal from 'vue-js-modal'
import Inputmask from 'inputmask';

Vue.use(Inputmask);

// register directive v-money and component <money>
Vue.use(money,  {
  decimal: ',',
  thousands: '.',
  prefix: 'R$ ',
  precision: 2,
  masked: false
});


Vue.use(Axios);

Vue.use(FlashMessage);

Vue.use(VModal);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  
  Vuelidate,
  render: (h) => h(App),
}).$mount("#app");
