export default {
    registerCookie: { 
        url: 'sanctum/csrf-cookie', 
        method: 'get', 
        baseURL: process.env.VUE_APP_AUTH_URL, 
    },
    sendPromoCode: {
        url: 'auth/send/promoCode',
        method: 'post'
    },
    getMailingFirstName: {
        url: 'mailings/firstname?AccessCode={code}',
        method: 'get'
    },
    getContacts: { 
        url: 'auth/contacts', 
        method: 'post', 
    },
    insertDebtor: {
        url: 'auth/insert',
        method: 'post'
    },
    login: { 
        url: 'auth/sanctum/token', 
        method: 'post',  
    },
    mailinglogin: { 
        url: 'mailings/token', 
        method: 'post',  
    },
    getUser: { 
        url: 'user/auth', 
        method: 'get', 
    },
    whatsText: { 
        url: 'whatsMessage', 
        method: 'get', 
    },
    getLinkAccess: {
        url: '/getLinkAccess',
        method: 'post',
    }
}