import auth from "@/middleware/auth";

export default [
    {
        path: "propostas",
        name: "propostas",
        meta: { middleware: [auth] },
        component: () => import("./Propostas")
    }
];
