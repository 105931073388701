import { store as auth } from '@/modules/auth';
import { store as layout } from '@/modules/layout';
import { store as meusContratos } from '@/pages/dashboard/meusContratos';
import { store as meusBoletos } from '@/pages/dashboard/meusBoletos';
import { store as comprovante } from '@/pages/dashboard/comprovante';
import { store as cadastro } from '@/pages/dashboard/cadastro';
import { store as meusAcordos } from '@/pages/dashboard/meusAcordos';   
import { store as negociar } from '@/pages/dashboard/negociar';   
import { store as boletoAcordo } from '@/pages/dashboard/boletoAcordo';   
import { store as vencimentoParcelas } from '@/pages/dashboard/vencimentoParcelas';   
import { store as acordoFechado } from '@/pages/dashboard/acordoFechado';   
import { store as acordoFechadoParcelas } from '@/pages/dashboard/acordoFechadoParcelas';   
import { store as propostas } from '@/pages/dashboard/propostas';    

export default {
    auth,
    layout, 
    meusContratos,
    meusBoletos,
    cadastro,
    comprovante,
    meusAcordos,
    negociar,
    boletoAcordo,
    vencimentoParcelas,
    acordoFechado,
    acordoFechadoParcelas,
    propostas,
}