import services from '@/http';
import { acordo, user } from '@/http';
import * as types from './mutation-types';

export const ActionFindAcordos = ({dispatch}, user_id) =>  {
    user.findAcordos.url = user.findAcordos.url.replace('{user_id}', user_id);
    services.http.request(user.findAcordos)
        .then(response => {
            dispatch('ActionSetAcordos', response.data.results);
        })
        .catch(error => {
            console.log(error);
        })
}

export const ActionFindAcordoById = (context, acordo_id) => {
    acordo.findAcordoById.url = acordo.findAcordoById.url.replace('{acordo_id}', acordo_id);
    return services.http.request(acordo.findAcordoById)
        .then(response => {
            return response.data.results;
        })
        .catch(error => {
            console.log(error);
            return error;
        })
}



export const ActionSetAcordos = ({commit}, acordos) => {
    commit(types.SET_ACORDOS, acordos);
}

export const ActionSetAcordo = ({commit}, acordo) => {
    commit(types.SET_ACORDO, acordo);
}