export default {

    getDebtsClaro: {
        url: 'claro/debts',
        method: 'post',
    },
    
    getOffersClaro: {
        url: 'claro/getOffers',
        method: 'post',
    },
    
    getAgreementClaro: {
        url: 'claro/agreement',
        method: 'post',
    },
    
    sendSmsOrEmail: { 
        url: 'sendOffer', 
        method: 'post', 
    },

    downloadBill: { 
        url: 'claro/boleto', 
        method: 'post', 
    },

    insertValuesContract: { 
        url: 'claro/insertValuesContract', 
        method: 'post', 
    },

    getValuesContract: { 
        url: 'claro/getValuesContract', 
        method: 'post', 
    },

    deleteValuesContract: { 
        url: 'claro/deleteValuesContract', 
        method: 'post', 
    },

    getInstallmentsClaro: { 
        url: 'claro/getInstallments', 
        method: 'get', 
    },

    insertLogByValueId: { 
        url: 'claro/insertLog', 
        method: 'post', 
    }

}