import auth from "@/middleware/auth";

export default [
  {
              path: "erro",
              name: "erro",
              meta: { middleware: [auth] },
              component: () => import(/* webpackChunkName: "home" */ "./Erro"),
          
  },
];
