import { routes as home } from '@/pages/home';
import { routes as dashboard } from '@/pages/dashboard';
import { routes as sobre } from '@/pages/sobre';
import { routes as mailing } from '@/pages/landing';
import { routes as pesquisa } from '@/pages/pesquisa';
import { routes as comprovante } from '@/pages/dashboard/comprovante';
import { routes as negociar } from '@/pages/dashboard/negociar';
import { routes as meusBoletos } from '@/pages/dashboard/meusBoletos';
import { routes as vencimentoParcelas } from '@/pages/dashboard/vencimentoParcelas';
import { routes as acordoFechado } from '@/pages/dashboard/acordoFechado';
import { routes as acordoFechadoParcelas } from '@/pages/dashboard/acordoFechadoParcelas';
import { routes as propostas } from '@/pages/dashboard/propostas';
import { routes as erro } from '@/pages/erro';

export default [
    ...home,
    ...dashboard,
    ...sobre,
    ...mailing,
    ...pesquisa,
    ...comprovante,
    ...negociar,
    ...meusBoletos,
    ...vencimentoParcelas,
    ...acordoFechado,
    ...acordoFechadoParcelas,
    ...propostas,
    ...erro,
    {
        path: "/:pathMatch(.*)",
        name: "notFound",
        component: () =>
          import(/* webpackChunkName: "not-found" */ "@/pages/NotFound"),
    },
    
];