import auth from "@/middleware/auth";

export default [
    {
        path: "resumoAcordo",
        name: "resumoAcordo",
        meta: { middleware: [auth] },
        component: () => import("./ResumoAcordo")
    }
];
