import axios from "axios";
import store from "@/store";
import { Object } from "core-js";
import servicesRoutes from "./services";
import flashMessages from '@/utils/flash-messages';

//import pkg from '../../package';

export const http = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  withCredentials: true,
  headers: { 
    'X-Requested-With': 'XMLHttpRequest',
    'X-Http-Owner-Id': process.env.VUE_APP_OWNER_ID,
    // 'Access-Control-Allow-Origin': process.env.VUE_APP_API_DEVICE
  }
})

http.interceptors.request.use((config) => {
  let ownerId = config.data?.httpOwnerId;
  if (ownerId){
      config.headers['X-Http-Owner-Id'] = ownerId;
    }
  return config;
});

http.interceptors.response.use(
  (response) => {
    return response;
  },
  function(error) {
    if ((error.response.status === 401 || error.response.status === 419)
    && error.response.config.url != 'updatePromoCode' 
    && error.response.config.url != 'mailings/token' 
    && error.response.config.url != 'auth/contacts'
    && error.response.config.url != 'auth/sanctum/token') {
      store.dispatch("auth/ActionDoLogout");
  }

  if (error.message === 'Network Error') {
    flashMessages.error('Não foi possível se comunicar com a API');
  }

  if (error.response?.status === 500) {
    flashMessages.error('Não foi possível executar a ação, tente novamente mais tarde.');
  }
    return Promise.reject(error.response);
  }
);

// rotas API organizadas
let services = {};

Object.keys(servicesRoutes).map((route) => {
  services[route] = servicesRoutes[route]
});

export const auth = services.auth;
export const contrato = services.contrato;
export const user = services.user;
export const cadastro = services.cadastro;
export const acordo = services.acordo;
export const comprovante = services.comprovante;
export const negociar = services.negociar;
export const boletoAcordo = services.boletoAcordo;
export const vencimentoParcelas = services.vencimentoParcelas;
export const acordoFechado = services.acordoFechado;
export const acordoFechadoParcelas = services.acordoFechadoParcelas;
export const propostas = services.propostas;
export const erro = services.erro;
export const setBearerToken = token => {
  http.defaults.headers.common['Authorization'] = `Bearer ${token}`
}

export default {
  http,
};