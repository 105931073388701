import { services as auth } from '@/modules/auth';
import { servicesUser as user } from '@/pages/dashboard';
import { services as contrato } from '@/pages/dashboard/meusContratos';
import { services as acordo } from '@/pages/dashboard/meusAcordos';
import { services as cadastro } from '@/pages/dashboard/cadastro';
import { services as comprovante } from '@/pages/dashboard/comprovante';
// import { services as negociar } from '@/pages/dashboard/negociar';
export default {
    auth,
    user,
    contrato,
    acordo,
    cadastro,
    comprovante,
    // negociar
}