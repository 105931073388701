import auth from "@/middleware/auth";

export default [
    {
        path: "acordoFechadoParcelas",
        name: "acordoFechadoParcelas",
        meta: { middleware: [auth] },
        component: () => import("./AcordoFechadoParcelas")
    }
];
