import auth from "@/middleware/auth";

export default [
    {
        path: "vencimentoParcelas",
        name: "vencimentoParcelas",
        meta: { middleware: [auth] },
        component: () => import("./VencimentoParcelas")
    }
];
